import img1 from "../images/eventLogo/designx.png";
import img2 from "../images/eventLogo/hackathon.png";
import img3 from "../images/eventLogo/appathon.png";
import img4 from "../images/eventLogo/robowars.png";
import img5 from "../images/eventLogo/overdrive.png";
import img6 from "../images/eventLogo/coastalclash.png";
import img7 from "../images/eventLogo/encipher.png";
import img8 from "../images/eventLogo/expo.png";
import img9 from "../images/eventLogo/vortex.png";
import img10 from "../images/eventLogo/expo.png";
import img11 from "../images/eventLogo/envision.png";
import img12 from "../images/eventLogo/E_FleetLogo.png";

const dataItem = [
  {
    url: "/designx",
    id: 1,
    img: img1,
    title: "DesignX ",
  },
  {
    url: "/hackathon",
    id: 2,
    img: img2,
    title: "HACK-A-THON ",
  },
  {
    url: "/appathon",
    id: 3,
    img: img3,
    title: "APPATHON ",
  },
  {
    url: "/robowar",
    id: 4,
    img: img4,
    title: "ROBOWAR ",
  },
  {
    url: "/overdrive",
    id: 5,
    img: img5,
    title: "OVERDRIVE ",
  },
  {
    url: "/envision",
    id: 11,
    img: img11,
    title: "ENVISION",
  },
  {
    url: "/coastalclash",
    id: 6,
    img: img6,
    title: "COSTAL CLASH ",
  },
  {
    url: "/encypherx",
    id: 7,
    img: img7,
    title: "ENCYPHERX",
  },
  {
    url: "/expo",
    id: 8,
    img: img8,
    title: "E-XPO",
  },
  {
    url: "/vortex",
    id: 9,
    img: img9,
    title: "vortex",
  },
  // {
  //     url: "/eventPage",
  //     id: 10,
  //     img: img10,
  //     title: 'E- FLEET ',
  // },

  {
    url: "/e-fleet",
    id: 12,
    img: img12,
    title: "E-FLEET",
  },
];

export default dataItem;
