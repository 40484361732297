import About from "./About";
import AdvisorsBackers from "./AdvisorsBackers";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import EventPage from "./Events/EventPage";
import Appathon from "./Events/Appathon";
import Gallery from "./Gallery";

import HelpCenter from "./HelpCenter";
import Home01 from "./Home01";
import Home02 from "./Home02";
import Home03 from "./Home03";
import Nfts from "./Nfts";
import ParticipantAssets from "./ParticipantAssets";
import Partners from "./Partners";
import RoadMap from "./RoadMap";
import Team from "./Team";
import VisionsMission from "./VisionsMission";
import CoastalClash from "./Events/CoastalClash";
import Encypherx from "./Events/Encypherx";
import Envision from "./Events/Envision";
import Expo from "./Events/Expo";
import Hackathon from "./Events/Hackathon";

import Overdrive from "./Events/Overdrive";
import Robowar from "./Events/Robowar";
import Vortex from "./Events/Vortex";
import EFleet from "./Events/EFleet";
import DesignX from "./Events/DesignX";
import Registration from "./Registration";
import Page404 from "./404";

const routes = [
  { path: "/", component: <Home01 /> },
  // { path: "/home-v2", component: <Home02 /> },
  // { path: "/home-v3", component: <Home03 /> },
  // { path: "/home-v3", component: <Home03 /> },
  { path: "/gallery", component: <Gallery /> },

  // { path: "/nfts", component: <Nfts /> },
  // { path: "/blog", component: <Blog /> },
  // { path: "/blog-details", component: <BlogDetails /> },
  // { path: "/visions-mission", component: <VisionsMission /> },
  // { path: "/help-center", component: <HelpCenter /> },
  // { path: "/participants-assets", component: <ParticipantAssets /> },
  // { path: "/advisors-backers", component: <AdvisorsBackers /> },
  // { path: "/partners", component: <Partners /> },
  { path: "/about", component: <About /> },
  // { path: "/road-map", component: <RoadMap /> },
  { path: "/team", component: <Team /> },
  { path: "/contact", component: <Contact /> },
  { path: "/eventPage", component: <EventPage /> },
  { path: "/appathon", component: <Appathon /> },
  { path: "/coastalclash", component: <CoastalClash /> },
  { path: "/encypherx", component: <Encypherx /> },
  { path: "/envision", component: <Envision /> },
  { path: "/expo", component: <Expo /> },
  { path: "/hackathon", component: <Hackathon /> },

  { path: "/overdrive", component: <Overdrive /> },
  { path: "/robowar", component: <Robowar /> },
  { path: "/vortex", component: <Vortex /> },
  { path: "/e-fleet", component: <EFleet /> },
  { path: "/designx", component: <DesignX /> },
  { path: "/workshops", component: <Blog /> },
  { path: "/register", component: <Registration /> },
  { path: "/*", component: <Home01 /> },
];

export default routes;
