import React, { useState } from "react";
import image from "../../assets/images/eventPoster/Design X.png";
import "./EventPage.css";
import Footer2 from "../../components/footer/Footer2";
import Header from "../../components/header/Header";
import Header1 from "../../components/header/Header";
import { Link } from "react-router-dom";

const DesignX = () => {
  return (
    <>
      <Header1 />
      <div className="container-bg ">
        <div className="shape right "></div>
        <div className="shape absolute top-[40%]"></div>
        <div className="blocks-container">
          <div className="container mx-auto mt-6 ">
            <div className=" ">
              <div className="w-full p-1 xl:p-4 gap-2 md:p-10 flex flex-col md:flex-row ">
                <div className="event-box left glass-bg rounded mb-4 w-full md:w-1/2 shadow-md text-center transform hover:scale-100 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="mb-4 p-2">
                    <img
                      src={image}
                      alt="Event"
                      className="deimg w-full h-auto rounded-lg"
                    />
                  </div>
                  <div className="flex gap-2 justify-center">
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSen2H-ny2PoQq7zwLsHKH5gs-CvVpAfGT6rec-ldYkQ3Nv5kg/viewform"
                      className="action-btn register-btn"
                    >
                      <span>Register</span>
                    </a>
                    <Link to = ""className="action-btn">
                      <span>Rulebook</span>
                    </Link>
                  </div>
                </div>
                <div className="event-box glass-bg rounded p-4 mb-4 w-full md:w-1/2 font-sans transform hover:scale-5 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="text-center">
                    <h3 className="text-4xl font-extrabold mb-4 text-grey-500 animate-slow-pulse">
                      DESIGNX
                    </h3>
                    <p className="text-lg mb-4 font-bold">
                      Explore the world of architectural design with a focus on
                      Building Information Modeling (BIM) at this engaging
                      event. <br />
                      Network with industry professionals, learn about the
                      latest advancements, and enhance your skills in BIM
                      design.
                    </p>
                    <p className="text-lg mb-4">
                      Design a BIM-based model using Revit software within a
                      3-hour time frame, with provided data and guidelines.
                    </p>
                    <p className="text-lg mb-4">
                      Participant Requirements: Personal laptops are allowed,
                      and no prior experience is needed.{" "}
                    </p>
                    <p className="text-lg mb-4">
                      Key Features: Participation Certificates provided.
                      <br />
                      All participants will receive a Certified CAD Centre
                      Certification.{" "}
                    </p>
                    <p className="text-lg font-bold mb-4">
                      Prize Amount:{" "}
                      <span className="prize-amount">INR 40,000</span>
                    </p>
                    <div className="car">
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 mb-2 md:mb-0 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Date:</b> 4th & 5th March
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Team Size:</b> Solo
                          </p>
                        </div>
                        <div className="w-full md:w-1/2 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b> Workshop Dates:</b>
                            <br />
                            1st & 2nd March
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Entry Fees:</b> INR 200
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="text-lg font-bold mt-8">
                      For more details contact: <br /> Updesh :-8080287489{" "}
                      <br /> Prachi :-8766011483
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </>
  );
};

export default DesignX;
