import React from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import Footer from "../components/footer/Footer";
import BlogList from "../components/blog/BlogList";
import dataBlog from "../assets/fake-data/data-blog";
import Header from "../components/header/Header";
import Footer2 from "../components/footer/Footer2";

function Blog(props) {
  return (
    <div className="wrapper">
      <Header />

      {/* <PageTitle title="Blog List" /> */}

      <BlogList data={dataBlog} />

      <Footer2 />
    </div>
  );
}

export default Blog;
