import React from 'react';



function Page404(props) {
    return (
        <>404 Not Found!</>
    );
}

export default Page404;