import img1 from "../images/Sponsors/shree comp.jpg";
import img2 from "../images/Sponsors/fed.png";
import img3 from "../images/Sponsors/gavran.png";
import img4 from "../images/Sponsors/abakus.png";
import img5 from "../images/Sponsors/fifthy.svg";
import img6 from "../images/Sponsors/TIME-FULL-LOGO.png";
import img7 from "../images/Sponsors/shah.png";

const dataPartner = [
  {
    id: 1,
    img: img4,
  },

  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img7,
  },
  {
    id: 4,
    img: img1,
  },

  {
    id: 5,
    img: img5,
  },
  {
    id: 6,
    img: img6,
  },
  {
    id: 7,
    img: img3,
  },
];

export default dataPartner;
