import img2 from "../images/eventLogo/designx.png";
import img3 from "../images/eventLogo/hackathon.png";
import img4 from "../images/eventLogo/appathon.png";
import img5 from "../images/eventLogo/overdrive.png";
import img6 from "../images/eventLogo/coastalclash.png";
import img7 from "../images/eventLogo/encipher.png";
import img8 from "../images/eventLogo/expo.png";
import img9 from "../images/eventLogo/envision.png";

const dataBlog = [
  //   {
  //     id: 1,
  //     img: img2,
  //     title: "DesignX",
  //     time: "February 25, 2024",
  //     text: "Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.",
  //   },
  {
    id: 2,
    img: img3,
    title: "HACK-A-THON",
    time: "February 28 and 29, 2022(11:00a.m. to 4:00p.m.)",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSf6fa1XvxUH3v4C37DtOvwS87WnjrRZsrvxrd8n-XjK1FS3jw/viewform",
    text: "Embark on a web development journey at our immersive workshop! Learn HTML, CSS, and JavaScript basics, explore advanced techniques, and engage in hands-on coding projects.",
  },
  {
    id: 3,
    img: img4,
    title: "APPATHON ",
    url: "/",
    time: "Dates already passed.",
    text: "Join us for an exciting Appathon! Dive into a whirlwind of creativity, coding, and collaboration as we develop innovative solutions to real-world challenges. Whether you're a seasoned developer or a novice enthusiast, this event promises an exhilarating journey of ideation, coding, and teamwork.",
  },
  {
    id: 4,
    img: img5,
    title: "OVERDRIVE ",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSdUyWF06FkW9UJoo8dqenneVz3oSF6aO5FQKjGfx51omOgACg/viewform",
    time: "February 16 & 22, 2024 (9a.m. to 4:15p.m.)",
    text: "Explore the fascinating world of automotive enhancement in our Overdrive Workshop! Unleash your creativity as you dive into high-performance upgrades, sleek modifications, and expert tuning.",
  },
  //   {
  //     id: 5,
  //     img: img6,
  //     title: "COSTAL CLASH ",
  //     time: "February 18, 2022",
  //     text: "Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.",
  //   },
  {
    id: 6,
    img: img7,
    title: "ENCYPHERX",
    url: "https://docs.google.com/forms/d/e/1FAIpQLScMNczf6GjGYc00BW-FutKB5qvLE2zbzD3oLbNlwCc6o0l-UQ/viewform",
    time: "February 20, 2022 onwards (9a.m. to 4:15p.m.)",
    text: "We are covering DevOps, Cybersecurity, Digital Forensics, Web Development, and much more. Whether you're a developer, cybersecurity enthusiast, or IT professional, our sessions offer hands-on learning tailored to your interests and skills. Unlock new opportunities and skills with us!",
  },
  {
    id: 7,
    img: img8,
    title: "E-XPO",
    time: "February 20, 2024 (9a.m. to 4:15p.m.)",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSd-b3g3IhHnKZAVKVs9SrfBMuGFTwLgqILYfuMHNrRKuReIpQ/viewform",
    text: "Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.",
  },
  {
    id: 8,
    img: img9,
    title: "ENVISION",
    time: "Dates already passed",
    url: "/",
    text: "No further details available.",
  },
];

export default dataBlog;
