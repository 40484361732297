import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import data from "../../assets/fake-data/dataPartner";

function Partner(props) {
  return (
    <div
      id="sponsors"
      className="brands block-text center flex flex-col items-center justify-center"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <h6 className="sub-heading">
        <span>Our Sponsors</span>
      </h6>

      <div className=" flex flex-row flex-wrap mx-1 gap-2 sm:flex-col-wrap">
        {data.map((idx) => (
          <Link
            to="#"
            className="flex justify-center  items-center  aspect-square max-h-40"
          >
            <img
              src={idx.img}
              width="180px"
              alt="technex"
              className="aspect-square object-contain w-full h-full
              "
            />
          </Link>
        ))}
      </div>
      {/* <SwiperSlide>
          <Link to="#">
            <img src={img1} alt="technex" />
          </Link>
        </SwiperSlide> */}
    </div>
  );
}

export default Partner;
