import img1 from "../images/members/adi.jpg";
import img2 from "../images/members/ud.jpg";
import img3 from "../images/members/rishika.jpg";
import img4 from "../images/members/gar.png";
import img5 from "../images/members/rus.jpg";
import img6 from "../images/members/pul.jpg";
import img7 from "../images/members/tan.jpg";
import img8 from "../images/members/bh.jpg";

const dataTeam = [
  {
    id: 1,
    img: img1,
    url: "https://www.linkedin.com/in/aditya-kharde-879236228?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    name: "Mr. Aditya Kharde",
    position: "MENTOR",
  },
  {
    id: 2,
    img: img2,
    name: "Mr. Uday Rudrakar",
    url: "https://www.linkedin.com/in/uday-rudrakar-22552a228?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    position: "COORDINATOR",
  },
  {
    id: 3,
    img: img3,
    name: "Ms. Ritika Ghosh",
    url: "https://www.linkedin.com/in/ritika-ghosh-11230b256?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    position: "COORDINATOR",
  },

  {
    id: 4,
    img: img4,
    name: "Ms. Gargi Fating",
    url: "https://www.linkedin.com/in/gargi-fating-730544230?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    position: "CO-COORDINATOR",
  },
  {
    id: 5,
    img: img5,
    name: "Mr. Rushabh Katekhaye",
    url: "https://www.linkedin.com/in/rushabh-katekhaye-6134a3235?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    position: "CO-COORDINATOR",
  },

  {
    id: 6,
    img: img6,
    name: "Ms. Pulkeshini Taksande",
    url: "https://www.linkedin.com/in/pulkeshini-taksande-a96856228?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    position: "CO-COORDINATOR",
  },
  {
    id: 7,
    img: img7,
    name: "Mr. Tanmay Dongre",
    url: "https://www.linkedin.com/in/tanmay-dongre-3a1864287?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    position: "CO-COORDINATOR",
  },
  {
    id: 8,
    img: img8,
    name: "Mr. Bhavesh Mahajan",
    url: "https://www.linkedin.com/in/bhavesh-mahajan-b4255922b",
    position: "WEBSITE HEAD",
  },

  // {
  //     id: 8,
  //     img: img8,
  //     name: 'Ralph Edwards',
  //     position: 'Developer'
  // },

  // {
  //     id: 9,
  //     img: img4,
  //     name: 'Cody Fisher',
  //     position: '3D Artist'
  // },

  // {
  //     id: 10,
  //     img: img1,
  //     name: 'Jane Cooper',
  //     position: 'Founder & CEO'
  // },

  // {
  //     id: 11,
  //     img: img2,
  //     name: 'Jenny Wilson',
  //     position: 'Designer'
  // },

  // {
  //     id: 12,
  //     img: img3,
  //     name: 'Ralph Edwards',
  //     position: 'Developer'
  // },
];

export default dataTeam;
