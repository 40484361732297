import React, { useState } from "react";
import image from "../../assets/images/eventPoster/Hackathon.png";
import "./EventPage.css";
import Footer2 from "../../components/footer/Footer2";
import Header from "../../components/header/Header";
import Header1 from "../../components/header/Header";
import { Link } from "react-router-dom";

const Hackathon = () => {
  return (
    <>
      <Header1 />
      <div className="container-bg ">
        <div className="shape right "></div>
        <div className="shape absolute top-[40%]"></div>
        <div className="blocks-container">
          <div className="container mx-auto mt-6 ">
            <div className=" ">
              <div className="w-full p-1 xl:p-4 gap-2 md:p-10 flex flex-col md:flex-row ">
                <div className="event-box left glass-bg rounded mb-4 w-full md:w-1/2 shadow-md text-center transform hover:scale-100 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="mb-4 p-2">
                    <img
                      src={image}
                      alt="Event"
                      className="hackimg w-full h-auto rounded-lg"
                    />
                  </div>
                  <div className="flex gap-2 justify-center">
                    <a
                      href="https://technex-hackathon.svpcet.in/"
                      className="action-btn register-btn"
                    >
                      <span>Register</span>
                    </a>
                    <Link to ="" className="action-btn">
                      <span>Rulebook</span>
                    </Link>
                  </div>
                </div>
                <div className="event-box glass-bg rounded p-4 mb-4 w-full md:w-1/2 font-sans transform hover:scale-5 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="text-center">
                    <h3 className="text-4xl font-extrabold mb-4 text-grey-500 animate-slow-pulse">
                      HACK-A-THON
                    </h3>
                    <p className="text-lg mb-4 font-bold">
                      A Hackathon is an intensive overnight event that assembles
                      talented programmers, experts, and creative minds to
                      collaborate on solving specific problems. Our Hackathon
                      event is divided into two rounds, each with its unique
                      role and purpose.
                    </p>
                    <p className="text-lg mb-4">
                    Phase 1: Ideation and problem exploration: Teams brainstorm and shape event direction, submitting solutions.

<br/>Phase 2: Main challenge and final evaluation: Teams craft solutions for challenges, judged for functionality, creativity, and impact.
                    </p>

            
                    <p className="text-lg font-bold mb-4 mt-4">
                      Prize Amount:{" "}
                      <span className="prize-amount">1,00,000</span>
                    </p>
                    <div className="car">
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 mb-2 md:mb-0 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Date:</b> 4th & 5th March
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Team:</b> 1-4 Members
                          </p>
                        </div>
                        <div className="w-full md:w-1/2 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Entry Fees:</b>Free
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Price:</b> Upto 1,00,000/-
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="text-lg font-bold mt-8">
                    Contact Details: <br /> Rutuja Kapate: 7020136003 <br /> Shantnu
                      Fartode: 9604650588
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </>
  );
};

export default Hackathon;
