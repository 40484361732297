import React, { useEffect, useState } from "react";
import {
  image1,
  image2,
  image12,
  image14,
  image15,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image16,
  image17,
  image18,
  image21,
} from "../assets/images/gallery";
import NavMenu from "../components/header/Header";
import "./Gallery.css";
import Footer from "../components/footer/Footer";
import Footer2 from "../components/footer/Footer2";
import Header from "../components/header/Header";
import Header1 from "../components/header/Header";

const images = [
  {
    image: image1,
    className: "",
  },
  {
    image: image2,
    className: "",
  },
  {
    image: image4,
    className: "tall",
  },
  {
    image: image3,
    className: "wide",
  },
  {
    image: image5,
    className: "",
  },
  {
    image: image6,
    className: "tall",
  },
  {
    image: image8,
    className: "big",
  },
  {
    image: image9,
    className: "",
  },
  {
    image: image12,
    className: "wide",
  },
  {
    image: image15,
    className: "big",
  },
  {
    image: image14,
    className: "tall",
  },
  {
    image: image7,
    className: "",
  },
  {
    image: image16,
    className: "",
  },
  {
    image: image17,
    className: "",
  },
  {
    image: image18,
    className: "",
  },
  {
    image: image21,
    className: "wide",
  },
];

const Gallery = () => {
  const [isShowNav, setIsShowNav] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      setIsShowNav(false);
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      <Header1 />
      {isShowNav && <NavMenu />}
      <section className="p-10 md:p-20 mt-[-180px]">
        <div className="md:px-12 xl:px-6">
          <div className="relative pt-36 ">
            <div className="lg:w-2/3 md:text-center mx-auto">
              {/* Add typewriter effect to the heading */}
              <h1 className="text-white font-bold text-4xl md:text-6xl xl:text-7xl ">
                GLIMPSES OF{" "}
                <span className="text-purple-300 whitespace-nowrap typewriter">
                  TECHNEX 2023.
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div className="grid-wrapper mt-10">
          {images.map((item, index) => (
            <div key={index} className={item.className}>
              <img src={item.image} alt="" />
            </div>
          ))}
        </div>
      </section>
      <Footer2 />
    </>
  );
};

export default Gallery;
