import React, { useState } from "react";
import image from "../../assets/images/eventPoster/EncipherX.png";
import "./EventPage.css";
import Footer2 from "../../components/footer/Footer2";
import Header from "../../components/header/Header";
import Header1 from "../../components/header/Header";
import { Link } from "react-router-dom";

const Encypherx = () => {
  return (
    <>
      <Header1 />
      <div className="container-bg ">
        <div className="shape right "></div>
        <div className="shape absolute top-[40%]"></div>
        <div className="blocks-container">
          <div className="container mx-auto mt-6 ">
            <div className=" ">
              <div className="w-full p-1 xl:p-4 gap-2 md:p-10 flex flex-col md:flex-row ">
                <div className="event-box left glass-bg rounded mb-4 w-full md:w-1/2 shadow-md text-center transform hover:scale-100 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="mb-4 p-2">
                    <img
                      src={image}
                      alt="Event"
                      className="encypherimg w-full h-auto rounded-lg"
                    />
                  </div>
                  <div className="flex gap-2 justify-center">
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSd80hymcTwJCFPlYO7H7pLUZoILrlSarcrsSQCA9LjPKrT7AQ/viewform"
                      className="action-btn register-btn"
                    >
                      <span>Register</span>
                    </a>
                    <Link to="" className="action-btn">
                      <span>Rulebook</span>
                    </Link>
                  </div>
                </div>
                <div className="event-box glass-bg rounded p-4 mb-4 w-full md:w-1/2 font-sans transform hover:scale-5 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="text-center">
                    <h3 className="text-4xl font-extrabold mb-4 text-grey-500 animate-slow-pulse">
                      ENCYPHERX 2.0
                    </h3>
                    <p className="text-lg mb-4 font-bold">
                      Unleash your inner hacker in this information security
                      challenge. Learn web exploitation, cryptography, digital
                      forensics, and more to master cybersecurity.
                    </p>
                    <p className="text-lg mb-4">
                      Participation Criteria: Undergraduate (UG) & Postgraduate
                      (PG) students and Teams.
                    </p>
                    {/* <ul>
                        <li>Machine Pwning</li>
                        <li>Digital Forensics</li>
                        <li>Web Exploitation</li>
                        <li>Reversing</li>
                        <li>Cryptography</li>
                        <li>Cryptography</li>
                      </ul> */}
                    <p className="text-lg mb-4">
                      CTF Challenges: Machine Pwning, Digital Forensics, Web
                      Exploitation, Reversing, Cryptography, OSINT
                    </p>
                    <p className="text-lg font-bold mb-4">
                      Price Amount:{" "}
                      <span className="prize-amount">Upto Rs.40,000</span>
                    </p>
                    <div className="car">
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 mb-2 md:mb-0 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Date:</b> 4th & 5th March
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Team Size:</b> 1-4
                          </p>
                        </div>
                        <div className="w-full md:w-1/2 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Entry Fees:</b> 350/-team
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Venue:</b> SVPCET
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="text-lg font-bold mt-8">
                      Contact Details: <br /> Rushi: +91 91464 51558 <br />{" "}
                      Shantnu : +91 90753 53888
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </>
  );
};

export default Encypherx;
