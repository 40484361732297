import React, { useState } from "react";
import image from "../../assets/images/eventPoster/Appathon.png";
import "./EventPage.css";
import Footer2 from "../../components/footer/Footer2";
import Header from "../../components/header/Header";
import Header1 from "../../components/header/Header";
import { Link } from "react-router-dom";

const Appathon = () => {
  return (
    <>
      <Header1 />
      <div className="container-bg ">
        <div className="shape right "></div>
        <div className="shape absolute top-[40%]"></div>
        <div className="blocks-container">
          <div className="container mx-auto mt-6 ">
            <div className=" ">
              <div className="w-full p-1 xl:p-4 gap-2 md:p-10 flex flex-col md:flex-row ">
                <div className="event-box left glass-bg rounded mb-4 w-full md:w-1/2 shadow-md text-center transform hover:scale-100 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="mb-4">
                    <img
                      src={image}
                      alt="Event"
                      className="appimg  rounded-lg"
                    />
                  </div>
                  <div className="flex gap-2 justify-center">
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSeEzRHEZKrNOXw1BE6-_efPfog1h83l-IVmg_kfhg3iluqsyQ/viewform"
                      className="action-btn register-btn"
                    >
                      <span>Register</span>
                    </a>
                    <Link to=""  className="action-btn rulebook-btn">
                      <span>Rulebook</span>
                    </Link>
                  </div>
                </div>
                <div className="event-box glass-bg rounded p-4 mb-4 w-full md:w-1/2 font-sans transform hover:scale-5 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="text-center">
                    <h3 className="text-4xl font-extrabold mb-4 text-grey-500 animate-slow-pulse">
                      APPATHON
                    </h3>
                    <p className="apptxt text-lg mb-4 font-bold">
                      "Embark on a coding adventure at Appathon, our thrilling
                      app development event where innovation meets teamwork.<br/>
                      Whether you're an experienced developer or a novice, this
                      is your chance to showcase your skills and creativity".
                    </p>
                    <p className="apptxt mb-4 text-lg">
                      Format: 
- Join from home or on-site for an immersive experience.<br/>
- Dive into coding excitement for a full day and night.<br/>


                    </p>
                    <p className="apptxt mb-4 text-lg">
                    Event Flow:
1. Receive the initial challenge, igniting app design creativity.<br/>
2. Code your application based on the challenge.<br/>
3. Sharpen skills with expert-led workshops, offering valuable insights and practical tips.<br/>
                    </p>
                   
                    <p className="text-lg font-bold mb-4 mt-4">
                      Prize Pool:{" "}
                      <span className="prize-amount">Rs.40,000</span>
                    </p>
                    <div className="car">
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 mb-2 md:mb-0 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Date:</b> 4th & 5th March
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Duration:</b> 24 Hours
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Platforms:</b> Flutter & Unity
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Workshop fee:</b> Free
                          </p>
                        </div>
                        <div className="w-full md:w-1/2 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                          <b>Registration Fees:</b><br/>Team of 3 students: ₹250<br/>
Team of 4 students: ₹300
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Team Size :</b>3-4
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="text-lg font-bold mt-8">
                    Contact Details: <br /> Anay Moharil: 74994 01573<br/>
Manthan Raut: 8007214181
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </>
  );
};

export default Appathon;
