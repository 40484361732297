import React, { useState } from "react";
import image from "../../assets/images/eventPoster/Robowars.png";
import "./EventPage.css";
import Footer2 from "../../components/footer/Footer2";
import Header from "../../components/header/Header";
import Header1 from "../../components/header/Header";
import { Link } from "react-router-dom";

const Robowar = () => {
  return (
    <>
      <Header1 />
      <div className="container-bg ">
        <div className="shape right "></div>
        <div className="shape absolute top-[40%]"></div>
        <div className="blocks-container">
          <div className="container mx-auto mt-6 ">
            <div className=" ">
              <div className="w-full p-1 xl:p-4 gap-2 md:p-10 flex flex-col md:flex-row ">
                <div className="event-box left glass-bg rounded mb-8 w-full md:w-1/2 shadow-md text-center transform hover:scale-100 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="image-container p-2 mb-4">
                    <img
                      src={image}
                      alt="Event"
                      className="roboimg rounded-lg"
                    />
                  </div>
                  <div className="flex gap-4 justify-center">
                    <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSe8-wEgJNgL-U19Jz-YYba0JOFtvrQ90SL2d4OcpZyUgwy8eQ/viewform" className="action-btn register-btn">
                      <span>Register</span>
                    </a>
                    <Link to = ""className="action-btn">
                      <span>Rulebook</span>
                    </Link>
                  </div>
                </div>
                <div className="event-box glass-bg rounded p-4 mb-8 w-full md:w-1/2 font-sans transform hover:scale-5 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="text-center">
                    <h3 className="text-4xl font-extrabold mb-4 text-grey-500 animate-slow-pulse">
                      ROBOWAR
                    </h3>
                    <p className="text-lg mb-4 font-bold ">
                      "Build to brawl, engineered to dominate."<br/> Participants
                      are expected to Design and build a robust robot adhering
                      to given guidelines and constraints to compete in the
                      tournament.{" "}
                    </p>
                    <p className="text-lg mb-4">
                    Bot Dimensions: No restrictions on bot dimensions; weight limit: 15 Kgs.
Winner Declaration: Top team on the leaderboard wins.<br/></p>
<p className="text-lg mb-4">
Event Rounds:
a) Group Battle: Teams compete in groups of 2 to 4 robots for 6 minutes. Winners advance.<br/>
b) Head-to-Head: Winning robots from group battles face off in a 6-minute head-to-head competition. The highest scorer wins.
                    </p>
                    
                  
                    
                    <p className="text-lg font-bold mb-4">
                      Price Amount:{" "}
                      <span className="prize-amount">Rs.1,60,000</span>
                    </p>
                    <div className="car">
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 mb-2 md:mb-0 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                          Event Dates : 4th & 5th March
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Team Size:</b> 1-6
                          </p>
                        </div>
                        <div className="w-full md:w-1/2 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                          Registration Fees : Rs.600/-
                          </p>
                          <p className="text-lg font-bold text-justify">
                          Result Date: 6th March
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Add other event details here */}
                    <div className="text-lg font-bold mt-8">
                      Contact Info:<br/> Amaan : 9669288387, Sayali : 9960515776
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </>
  );
};

export default Robowar;
