import React, { useState } from "react";
import image from "../../assets/images/eventPoster/Envision.png";

import "./EventPage.css";
import Footer2 from "../../components/footer/Footer2";
import Header from "../../components/header/Header";
import Header1 from "../../components/header/Header";
import { Link } from "react-router-dom";

const Robowar = () => {
  return (
    <>
      <Header1 />
      <div className="container-bg ">
        <div className="shape right "></div>
        <div className="shape absolute top-[40%]"></div>
        <div className="blocks-container">
          <div className="container mx-auto mt-6 ">
            <div className=" ">
              <div className="w-full p-1 xl:p-4 gap-2 md:p-10 flex flex-col md:flex-row ">
                <div className="event-box left glass-bg rounded mb-8 w-full md:w-1/2 shadow-md text-center transform hover:scale-100 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="image-container p-2 mb-4">
                    <img
                      src={image}
                      alt="Event"
                      className="envimg rounded-lg"
                    />
                  </div>
                  <div className="flex  justify-center">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScf6RPFlhaLF2eHVBn0ayIaPcsEFPASfWOBFBBz6VsXhLWGQA/viewform" className="action-btn register-btn">
                      <span>Register</span>
                    </a>
                    <Link to = ""className="action-btn">
                      <span>Rulebook</span>
                    </Link>
                  </div>
                </div>
                <div className="event-box glass-bg rounded p-4 mb-8 w-full md:w-1/2 font-sans transform hover:scale-5 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="text-center">
                    <h3 className="text-4xl font-extrabold mb-4 text-grey-500 animate-slow-pulse">
                      ENVISION
                    </h3>
                    <p className="envt text-lg font-bold text-center">
                     Learn machine learning, data analysis, and Power BI. 
                    </p>
                   <p className="envt text-lg mb-4 "><br/>• Dive into practical sessions, build prediction models, and create interactive dashboards.<br/> • Perfect for students and data enthusiasts. <br/>• Unlock the power of data!
                  <br/>• Practical sessions with real datasets for building prediction models and creating interactive dashboards.
<br/>• Two-day workshop, three hours each day, covering all necessary skills for the event.
                    </p>
                  
                    
                    <p className="text-lg font-bold mb-4">
                      Price Pool:{" "}
                      <span className="prize-amount">Rs.40,000</span>
                    </p>
                    <div className="car">
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 mb-2 md:mb-0 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                          Event Dates : 4th & 5th March
                          </p>
                          <p className="text-lg font-bold text-justify">
                            Team Size: 1-2
                          </p>
                          <p className="text-lg font-bold text-justify">
                            Venue: SVPCET, Nagpur
                          </p>
                          
                        </div>
                        <div className="w-full md:w-1/2 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                          Registration Fees:<br/> Rs.150 for Solo, Rs.200 for Duo
                          </p>
                          <p className="text-lg font-bold text-justify">
                            Workshop Dates:<br/>16th & 17th Feb
                          </p>

                        </div>
                      </div>
                    </div>
                    {/* Add other event details here */}
                    <div className="text-lg font-bold mt-8">
                      Contact Info:<br/> Atmanya: 8830893251<br/>
Niharika: 8007672833  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </>
  );
};

export default Robowar;
