import React, { useState } from "react";
import image from "../../assets/images/eventPoster/Vortex.png";
import "./EventPage.css";
import Footer2 from "../../components/footer/Footer2";
import Header from "../../components/header/Header";
import Header1 from "../../components/header/Header";
import { Link } from "react-router-dom";

const Vortex = () => {
  return (
    <>
      <Header1 />
      <div className="container-bg ">
        <div className="shape right "></div>
        <div className="shape absolute top-[40%]"></div>
        <div className="blocks-container">
          <div className="container mx-auto mt-6 ">
            <div className=" ">
              <div className="w-full p-1 xl:p-4 gap-2 md:p-10 flex flex-col md:flex-row ">
                <div className="event-box left glass-bg rounded mb-4 w-full md:w-1/2 shadow-md text-center transform hover:scale-100 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="mb-4 p-2">
                    <img
                      src={image}
                      alt="Event"
                      className="vorbtn w-full h-auto rounded-lg"
                    />
                  </div>
                  <div className="flex gap-2 justify-center pb-3">
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSf-yB9KWPAU_Poh2riYXkgxwiDQ93eSIstg9EfVR9bNZX5-wg/viewform"
                      className="action-btn register-btn"
                    >
                      <span>Register</span>
                    </a>
                    <Link to = ""className="action-btn">
                      <span>Rulebook</span>
                    </Link>
                  </div>
                </div>
                <div className="event-box glass-bg rounded p-4 mb-4 w-full md:w-1/2 font-sans transform hover:scale-5 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="text-center">
                    <h3 className="text-4xl font-extrabold mb-4 text-grey-500 animate-slow-pulse">
                      VORTEX
                    </h3>
                    <p className="mb-3 text-lg font-bold">
                      "VORTEX" is a Valorant eSports event. <br />
                      Teams compete in 5v5 matches, aiming to win 13 rounds for
                      victory. Join us to unleash your gaming skills and become
                      the inaugural Vortex champions.
                    </p>
                    <p className="mb-2">
                      Format:
                      <br />
                      - Participate remotely or in person at our college campus
                      for competitive gaming.
                      <br />
                      - Employing a single-elimination bracket system,
                      intensifying competition.
                      <br />
                      - Teams progress through brackets, facing elimination
                      after a single loss.
                      <br />
                    </p>
                    <p className=" mb-4">
                      Event Flow:
                      <br />
                      1. Matchups:Event brackets revealed on Discord before
                      battles.
                      <br />
                      2. Toss: Virtual toss on Discord determines map choice and
                      attacking/defending sides.
                      <br />
                      3. Final Showdown:Custom battle; first team to win 13
                      rounds advances.
                      <br />
                      4. Live Screening:Matches broadcasted on screens across
                      campus.
                      <br />
                    </p>
                    {/* <p className="text-lg font-bold mb-4">
                      Price Amount:{" "}
                      <span className="prize-amount">INR 1650</span>
                    </p> */}
                    <div className="car">
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 mb-2 md:mb-0 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Date:</b> 4th & 5th March
                          </p>
                          <p className="text-lg font-bold text-justify">
                            Team Size: 4-5
                          </p>
                        </div>
                        <div className="w-full md:w-1/2 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            Registration Fees: ₹400 per team
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Price:</b> Upto ₹ 1,00,000
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="text-lg font-bold mt-8">
                      Coordinator :-Abhishrut Rokade (+91 9766498862) <br />
                      Co-coordinator:- Aditya Umathe (+91 9420258289)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </>
  );
};

export default Vortex;
