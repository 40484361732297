import React from "react";
import Footer2 from "../components/footer/Footer2";
import Header1 from "../components/header/Header1";
import "./registration.css";
const Registration = () => {
  return (
    <div>
      <Header1 />
      <div className="home-3 wrapper md:w-full sm:max-w-[80%] sm:mx-20 mx-auto">
        {/* // <div className="wrapper mx-auto max-w-2xl my-8"> */}
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg md:mx-20  flex flex-col justify-center items-center">
          <h2
            className="text-5xl my-8 "
            style={{ fontFamily: "Black Ops One" }}
          >
            REGISTER NOW
          </h2>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 text-bold text-xl">
                  Sr No.
                </th>
                <th scope="col" className="px-6 py-3 text-bold text-xl">
                  Event Name
                </th>
                <th scope="col" className="px-6 py-3 text-bold text-xl">
                  Link
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  1
                </th>
                <td className="px-6 py-4">Vortex</td>
                <td className="px-6 py-4">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSf-yB9KWPAU_Poh2riYXkgxwiDQ93eSIstg9EfVR9bNZX5-wg/viewform"
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Click To Regester
                  </a>
                </td>
              </tr>
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  2
                </th>
                <td className="px-6 py-4">DesignX</td>
                <td className="px-6 py-4">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSen2H-ny2PoQq7zwLsHKH5gs-CvVpAfGT6rec-ldYkQ3Nv5kg/viewform"
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Click To Register
                  </a>
                </td>
              </tr>
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  3
                </th>
                <td className="px-6 py-4">eXpo </td>

                <td className="px-6 py-4">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeEFRr7wXD2EOZPgbl57RccuWAezW3zUziawmACw1Dz-vW2pA/viewform"
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Click To Register
                  </a>
                </td>
              </tr>
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  4
                </th>
                <td className="px-6 py-4">EncipherX</td>

                <td className="px-6 py-4">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd80hymcTwJCFPlYO7H7pLUZoILrlSarcrsSQCA9LjPKrT7AQ/viewform"
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Click To Register
                  </a>
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  5
                </th>
                <td className="px-6 py-4">Envision</td>

                <td className="px-6 py-4">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLScf6RPFlhaLF2eHVBn0ayIaPcsEFPASfWOBFBBz6VsXhLWGQA/viewform"
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Click To Register
                  </a>
                </td>
              </tr>
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  6
                </th>
                <td className="px-6 py-4">Appathon</td>

                <td className="px-6 py-4">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdWW7_qCz2m722BM9QlrcWd_feTTq6ki87VAshrWcE5VPbkBg/viewform"
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Click To Register
                  </a>
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  7
                </th>
                <td className="px-6 py-4">Coastal Clash</td>

                <td className="px-6 py-4">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdCy8MgwkKLc2ZnUIFMhmVsOlTL0fwrMA8eWjVTg36Ci0_jMA/viewform"
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Click To Register
                  </a>
                </td>
              </tr>
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  8
                </th>
                <td className="px-6 py-4">Overdrive</td>

                <td className="px-6 py-4">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfWQmAAsEGRjZaGEegp2IXmYeXCgWShlHRi2zwT1Su8-bqe_Q/viewform"
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Cick To Register
                  </a>
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  dark:text-white"
                >
                  9
                </th>
                <td className="px-6 py-4">Robowar</td>

                <td className="px-6 py-4 texl-xl">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSe8-wEgJNgL-U19Jz-YYba0JOFtvrQ90SL2d4OcpZyUgwy8eQ/viewform"
                    className="font-bold text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Click To Register
                  </a>
                </td>
              </tr>
            </tbody>
            {/* <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr></tr>
            </thead> */}
          </table>
        </div>
        <Footer2 className="mt-10" />
      </div>
    </div>
  );
};

export default Registration;
