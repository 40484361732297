import React , {useState} from 'react';

import img1 from '../../assets/images/gallery/6.JPG';
import img2 from '../../assets/images/layouts/about-02.png'
import img3 from '../../assets/images/layouts/about-03.png'
import img4 from '../../assets/images/layouts/about-04.png'
import img5 from '../../assets/images/layouts/about-05.png'
import Button from '../button/Button';



function About(props) {

    const [dataImg] = useState([
        {
            id: 1,
            img : img1,
            class: 'img1'
        },
        // {
        //     id: 2,
        //     img : img2,
        //     class: 'img2'
        // },
        // {
        //     id: 3,
        //     img : img3,
        //     class: 'img3'
        // },
        // {
        //     id: 4,
        //     img : img4,
        //     class: 'img4'
        // },
        // {
        //     id: 5,
        //     img : img5,
        //     class: 'img5'
        // },
    ])

    const [dataBlock] = useState(
        {
            subheading: 'About us',
            heading: 'About Us',
            desc1: 'Technex is our flagship Technical event held annually which attracts hundreds of Engineering students from different parts of the country. Technex is an event which is driven and organized by a team of students and mentored by a team of faculties. The event was started in 2007 with the motive of encouraging technology, scientific thinking and innovation among young engineers and giving them a platform to enhance their skills. Currently Technex is recognized as Central India’s largest Science and technology fest with an annual participation of more than 2500+ technology enthusiasts.' ,
            desc2 :'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occae cat cupidatat non proident, sunt in culpa qui officia dese runt mollit anim id est laborum velit esse cillum dolore eu fugiat nulla pariatu epteur sint occaecat'

        }
    )
    return (
        <section id='about' className="about xl:mx-[5%]">
                <div className="shape"></div>
                <div className="container">
                    <div className="row rev">
                        <div className="col-xl-6 col-md-12 flex justify-center items-center">
                            <div className="about__right">
                                <div className="images">
                                    {
                                        dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="technex" />
                                        ))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="block-text" >
                                {/* <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6> */}
                                <h3 className="heading xl:text-[40px] sm:text-[40px] lg:text-[40px] text-[30px] font-bold">{dataBlock.heading}</h3>
                                    <p className="mb-17 text-[18px] " style={{lineHeight: "1.3"}}>{dataBlock.desc1}</p>
                                    {/* <p className="mb-26">{dataBlock.desc2}</p> */}
                                    <Button link='/about' title='More About Us' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default About;