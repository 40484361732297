import React, { useState } from "react";
import image from "../../assets/images/eventPoster/Expo.png";
import "./EventPage.css";
import Footer2 from "../../components/footer/Footer2";
import Header from "../../components/header/Header";
import Header1 from "../../components/header/Header";
import { Link } from "react-router-dom";

const Expo = () => {
  return (
    <>
      <Header1 />
      <div className="container-bg ">
        <div className="shape right "></div>
        <div className="shape absolute top-[40%]"></div>
        <div className="blocks-container">
          <div className="container mx-auto mt-6 ">
            <div className=" ">
              <div className="w-full p-1 xl:p-4 gap-2 md:p-10 flex flex-col md:flex-row ">
                <div className="event-box left glass-bg rounded mb-4 w-full md:w-1/2 shadow-md text-center transform hover:scale-100 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="mb-4 p-2">
                    <img
                      src={image}
                      alt="Event"
                      className="expoimg w-full h-auto rounded-lg"
                    />
                  </div>
                  <div className="flex gap-28 justify-center">
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSeEFRr7wXD2EOZPgbl57RccuWAezW3zUziawmACw1Dz-vW2pA/viewform"
                      className="action-btn"
                    >
                      <span className="regbtn">Register</span>
                    </a>
                    <Link to="/contact" className="action-btn">
                      <span className="rulebtn">Rulebook</span>
                    </Link>
                  </div>
                </div>
                <div className="event-box glass-bg rounded p-4 mb-4 w-full md:w-1/2 font-sans transform hover:scale-5 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="text-center">
                    <h3 className="text-4xl font-extrabold mb-4 text-grey-500 animate-slow-pulse">
                      EXPO
                    </h3>
                    <p className="expot text-lg font-bold">
                    "eXpo" is part of TECHNEX, India's seventh-largest tech fest, which spans for two days, promoting entrepreneurship</p>
                    <p className="expot text-lg mb-4">
<br/>• The event provides a platform for individuals nationwide to pitch innovative business ideas.
<br/>• Five distinct activities aim to foster entrepreneurial spirit.
<br/>• Aspiring entrepreneurs can showcase creativity, business acumen, and problem-solving skills.
<br/>• Four judging rounds in eXpo assess participants' ideas and entrepreneurial skills.
<br/>• Workshops feature insights from successful entrepreneurs like Sneha Taori and Shivam Joshi, guiding through the entrepreneurial journey.               
                    </p>
                    <p className="text-lg font-bold mb-4">
                      Price Amount:{" "}
                      <span className="prize-amount">Upto Rs.1,00,000</span>
                    </p>
                    <div className="car">
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 mb-2 md:mb-0 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Date:</b> 4th & 5th March
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Team Size:</b> 1-4
                          </p>
                        </div>
                        <div className="w-full md:w-1/2 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Entry Fees:</b> Rs.200 for Solo<br/> and Rs.300 for Group
                          </p>
                         
                        </div>
                      </div>
                    </div>
                    <div className="contact-info text-lg font-bold mt-8 ">
                      Contact Info:<br/> Allen Jess : 7985606935,<br/> Ayush Benny : 8606304705
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </>
  );
};

export default Expo;
