import React, { useState } from "react";
import image from "../../assets/images/eventPoster/e-fleet.png";
import "./EventPage.css";
import Footer2 from "../../components/footer/Footer2";
import Header from "../../components/header/Header";
import Header1 from "../../components/header/Header";
import { Link } from "react-router-dom";

const EFleet = () => {
  return (
    <>
      <Header1 />
      <div className="container-bg ">
        <div className="shape right "></div>
        <div className="shape absolute top-[40%]"></div>
        <div className="blocks-container">
          <div className="container mx-auto mt-6 ">
            <div className=" ">
              <div className="w-full p-1 xl:p-4 gap-2 md:p-10 flex flex-col md:flex-row ">
                <div className="event-box left glass-bg rounded mb-4 w-full md:w-1/2 shadow-md text-center transform hover:scale-100 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="mb-4 p-2">
                    <img
                      src={image}
                      alt="Event"
                      className="hackimg w-full h-auto rounded-lg"
                    />
                  </div>
                  <div className="flex gap-2 justify-center">
                    <a href="" className="action-btn register-btn">
                      <span>Register</span>
                    </a>
                    <Link to = ""className="action-btn">
                      <span>Rulebook</span>
                    </Link>
                  </div>
                </div>
                <div className="event-box glass-bg rounded p-4 mb-4 w-full md:w-1/2 font-sans transform hover:scale-5 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="text-center">
                    <h3 className="text-4xl font-extrabold mb-4 text-grey-500 animate-slow-pulse">
                      E-FLEET
                    </h3>
                    <p className="text-lg mb-4 font-bold">
                      The Electrical Vehicle Event aims to educate attendees
                      about the benefits of electrical vehicles (HEVs, BEVs),
                      showcase various models of car and bikes brands, and
                      encourage sustainable transportation choices.
                    </p>
                    <p className="text-lg mb-4">
                      <b>What to Expect:</b> <br />
                      Showcase of electric car and bike models. <br />
                      Experience cutting-edge electric vehicle technology. <br />
                      Discover environmental benefits and cost savings. <br />
                      <br />
                      <b>Audience:</b> Students, enthusiasts, anyone curious
                      about transportation's future.
                    </p>

                    <div className="car">
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 mb-2 md:mb-0 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Date:</b> 4th & 5th March
                          </p>
                        </div>
                        <div className="w-full md:w-1/2 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Entry Fees:</b> Free
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="text-lg font-bold mt-8">
                      Contact Details: <br /> Uday Rudrakar: 8698575167 <br />{" "}
                      Ravi Pandey: 9111957538
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </>
  );
};

export default EFleet;
