import React, { useState } from "react";
import image from "../../assets/images/eventPoster/Overdrive.png";
import "./EventPage.css";
import Footer2 from "../../components/footer/Footer2";
import Header from "../../components/header/Header";
import Header1 from "../../components/header/Header";
import { Link } from "react-router-dom";

const Overdrive = () => {
  return (
    <>
      <Header1 />
      <div className="container-bg ">
        <div className="shape right "></div>
        <div className="shape absolute top-[40%]"></div>
        <div className="blocks-container">
          <div className="container mx-auto mt-6 ">
            <div className=" ">
              <div className="w-full p-1 xl:p-4 gap-2 md:p-10 flex flex-col md:flex-row ">
                <div className="event-box left glass-bg rounded mb-4 w-full md:w-1/2 shadow-md text-center transform hover:scale-100 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="mb-4 p-2">
                    <img
                      src={image}
                      alt="Event"
                      className="overimg w-full h-auto rounded-lg"
                    />
                  </div>
                  <div className="flex gap-2 justify-center">
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSdUyWF06FkW9UJoo8dqenneVz3oSF6aO5FQKjGfx51omOgACg/viewform"
                      className="action-btn register-btn"
                    >
                      <span>Register</span>
                    </a>
                    <Link to = ""className="action-btn">
                      <span>Rulebook</span>
                    </Link>
                  </div>
                </div>
                <div className="event-box glass-bg rounded p-4 mb-4 w-full md:w-1/2 font-sans transform hover:scale-5 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="text-center">
                    <h3 className="text-4xl font-extrabold mb-4 text-grey-500 animate-slow-pulse">
                      OVERDRIVE 6.0
                    </h3>
                    <p className="text-lg mb-4 font-bold">
                      Presents the most awaited event which set the hearts of
                      the driver and the wheels of the bot on fire...
                    </p>
                    <p className="text-left mb-4 text-lg">
                    1. Compete Among the Elite or Challenge Yourself with the Rest!<br/>
2. Navigate your vehicle through the arena and vie for enticing prizes.<br/>
3. Engage in a National-level Robotics Competition, competing alongside participants from across the nation to complete the arena with your bot in minimal time.<br/>
4. Workshop Available with Takeaway Kit. Workshop Fee: ₹3200/- per group.<br/>
5. Interested in racing your vehicle on the track or maneuvering it on the lawn? Join us now!<br/>
                    </p>

                    <p className="text-lg font-bold mb-4">
                      Price Pool:{" "}
                      <span className="prize-amount">Rs.80,000</span>
                    </p>
                    <div className="car">
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 mb-2 md:mb-0 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Date:</b> 4th & 5th March
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Team Size:</b> 2-4
                          </p>
                        </div>
                        <div className="w-full md:w-1/2 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Entry Fees:</b> 600/- per team
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Venue:</b> SVPCET, Nagpur
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="text-lg font-bold mt-8">
                    Contact Details: <br /> Gunjan: +919860679823<br/>
      Aditya: +918261060193

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </>
  );
};

export default Overdrive;
