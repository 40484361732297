import React, { useState } from "react";
import image from "../../assets/images/eventPoster/coastalclash.png";
import "./EventPage.css";
import Footer2 from "../../components/footer/Footer2";
import Header from "../../components/header/Header";
import Header1 from "../../components/header/Header";
import { Link } from "react-router-dom";

const CoastalClash = () => {
  return (
    <>
      <Header1 />
      <div className="container-bg ">
        <div className="shape right "></div>
        <div className="shape absolute top-[40%]"></div>
        <div className="blocks-container">
          <div className="container mx-auto mt-6 ">
            <div className=" ">
              <div className="w-full p-1 xl:p-4 gap-2 md:p-10 flex flex-col md:flex-row ">
                <div className="event-box left glass-bg rounded mb-4 w-full md:w-1/2 shadow-md text-center transform hover:scale-100 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="mb-4 p-2">
                    <img
                      src={image}
                      alt="Event"
                      className="coastimg w-full h-auto rounded-lg"
                    />
                  </div>
                  <div className="flex gap-2 justify-center">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSflUDEVwHGBj0xp94Fj8F1wK8rB0WM-vBmcW3rzOkevJXFcEQ/viewform" className="action-btn register-btn">
                      <span>Register</span>
                    </a>
                    <Link to= ""className="action-btn">
                      <span>Rulebook</span>
                    </Link>
                  </div>
                </div>
                <div className="event-box glass-bg rounded p-4 mb-4 w-full md:w-1/2 font-sans transform hover:scale-5 transition duration-300 hover:shadow-lg relative overflow-hidden glow-shadow">
                  <div className="text-center">
                    <h3 className="text-4xl font-extrabold mb-4 text-grey-500 animate-slow-pulse">
                      COASTALCLASH
                    </h3>
                    <p className="text-lg mb-4 font-bold">
                      Coastal Clash, an electrifying RC boat
                      competition where students dive into hands-on experiences
                      by crafting their own boats.<br/> Before navigating the Coastal
                      Clash Arena, an exciting pre-workshop on boat building
                      will be provided.
                    </p>

                    <p className="text-left text-lg">
                      {" "}
                      Format : An event will be in offline
                      mode.<br/> 
                      Event Flow : <br /> Round 1- Participants have to cross the
                      hurdles and reach to the final destination within minimum
                      time. <br /> Rounds 2-The selected participants from round 1 will
                      play in this round for finals .
                    </p>
                    {/* <p className="mt-4 mb-4 text-left text-lg">
                      Workshop: Enhance your knowledge with an insightful
                      workshop, providing valuable guidance on circuit
                      designing, 3D design of a boat.
                    </p> */}
                    <p className="text-lg font-bold mb-4 mt-4">
                      Prize Pool:{" "}
                      <span className="prize-amount">Rs.60,000</span>
                    </p>

                    <div className="car">
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 mb-2 md:mb-0  md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Date:</b> 4th & 5th March
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Team:</b> MAX 4 Members
                          </p>
                        </div>
                        <div className="w-full md:w-1/2 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Entry Fee:</b> 500/-
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Workshop Fee:</b> 1500/-
                          </p>
                        </div>
                      </div>
                    </div>
                     <div className="text-lg font-bold mt-8">
                     For more details contact: <br /> Nupendra:7666061856 <br />
                      Shashwati:7745041387
                    </div>

                    {/* <p className="text-lg font-bold mb-4">
                      Price Amount:{" "}
                      <span className="prize-amount">INR 1650</span>
                    </p>
                    <div className="car">
                      <div className="flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 mb-2 md:mb-0 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Date:</b> 12/02/2024
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Venue:</b> svpcet
                          </p>
                        </div>
                        <div className="w-full md:w-1/2 text-left md:text-right">
                          <p className="text-lg font-bold text-justify">
                            <b>Duration:</b> 12-14 Hours
                          </p>
                          <p className="text-lg font-bold text-justify">
                            <b>Price:</b> 12000/-
                          </p>
                        </div>
                      </div>
                    </div> */}
                    {/* Add other event details here */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </>
  );
};

export default CoastalClash;
